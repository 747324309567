import React from 'react';
import { Link } from 'react-router-dom';
import getThumbnailFeatureImage from '../../assets/stand-in-icn.png';
import getCharacterImage from '../../assets/Character.png';
import AppStoreBlack from '../../assets/AppStoreBlack (1).png';
import GooglePlayBlack from '../../assets/GooglePlayBlack.png';
import getJourneyIcon1Image from '../../assets/JourneyIcon1.png';
import getJourneyIcon2Image from '../../assets/JourneyIcon2.png';
import getJourneyIcon3Image from '../../assets/JourneyIcon3.png';
import getJourneyIcon4Image from '../../assets/JourneyIcon4.png';
import getJourneyIcon5Image from '../../assets/JourneyIcon5.png';
import getJourneyIcon6Image from '../../assets/JourneyIcon6.png';
import getBottomIcon1 from '../../assets/BottomIcon1.png';
import getBottomIcon2 from '../../assets/BottomIcon2.png';
import getBottomIcon3 from '../../assets/BottomIcon3.png';
import getPhone1 from '../../assets/NewScreen1.png';
import getPhone2 from '../../assets/NewScreen2.png';
import getPhone3 from '../../assets/PhoneScreen3.png';
import getWhitePlayApp from '../../assets/AppleAppStoreWhite.png';
import getGooglePlayApp from '../../assets/GooglePlayWhite.png';

const Home = () => {
  
  const [headerClasses, setHeaderClasses] = React.useState('header') 

  document.addEventListener('scroll', function(){
    if(window.scrollY !== 0){
      setHeaderClasses('header active');
    }
    else{
      setHeaderClasses('header');
    }
  })
  return (
    <div id="wrapper">
      <header className={headerClasses}>
        <div className="container d-flex justify-content-between align-items-center pt-2 pb-2">
          <a className="logo" href="#">
            <img src={getThumbnailFeatureImage} width={31} height={31} alt="logo icon" />
            <span>Stand-in</span>
          </a>
          <a className="cta-link" target="blank" href="mailto:questions@standin.ca?subject=Product Support">Contact Us</a>
        </div>
      
      </header>
      <section className="hero-section pb-7 pb-md-0 pt-10 pt-md-9">
        <div className="container">
          <div className="row align-items-start">
            <div className="col-12 col-md-6 figure-holder">
              <figure>
                <img src={getCharacterImage} alt="image description" width={455} height={378} />
              </figure>
            </div>
            <div className="col-12 col-md-6 pb-md-5">
              <div className="title">
                <h1>Stand-in</h1>
              </div>
              <h2>Everywhere you want to go.
                <br /> None of the lines.
              </h2>
              <p>Install today for a line-free tomorrow.</p>
              <div className="btn-holder">
                <a title="Click to open link in new tab" target="blank" href="https://apps.apple.com/us/app/id1526847707">
                  <img src={AppStoreBlack} alt="App-store" height={48} width={167} />
                </a>
                <a title="Click to open link in new tab" target="blank" href="https://play.google.com/store/apps/details?id=com.mobilelive.standin&hl=en">
                  <img src={GooglePlayBlack} alt="Google-app" height={48} width={167} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <main>
        <section className="features-section pt-5 pt-lg-7">
          <div className="container">
            <div className="row justify-content-md-between">
              <div className="col-12 col-md-7 features-desc order-md-1">
                <div className="side-text">
                  <h2>Why?</h2>
                  <strong className="d-block"> Waiting in line isn't fun.</strong>
                  <p>Capacity limitations and physical distancing have made lines not only more common, but unsafe. </p>
                </div>
                <div className="side-text">
                  <h2>What? </h2>
                  <p>
                    <strong>Stand-in</strong> is an app that regulates the flow of people going inside so you don't have
                    to wait in line outside.
                  </p>
                </div>
                <div className="side-text">
                  <h2>How?</h2>
                  <p>
                    <strong>Stand-in</strong> tells you when to arrive so you avoid lines. It even works if you don't have
                    the app.
                  </p>
                </div>
                <div className="side-text">
                  <h2>Who?</h2>
                  <p>
                    <strong>Stand-in</strong> provides a seamless and contactless experience for both customers and
                    businesses.
                  </p>
                </div>
                <div className="side-text ad">
                  <h2>Wow!</h2>
                  <p>
                    <strong>It's free. Install today.</strong>
                  </p>
                </div>
              </div>
              <div className="pt-7 pb-5 pt-md-0 pb-md-0 col-12 col-md-5 col-lg-4 flow-chart order-md-0">
                <div className="chart-holder">
                  <div className="pb-4 pb-xl-7 step-wrap clearfix">
                    <div className="step odd">
                      <figure>
                        <img src={getJourneyIcon1Image} alt="image description" width={30} height={30} />
                      </figure>
                      <p>View stores &amp; locations</p>
                    </div>
                  </div>
                  <div className="step-wrap pb-4 pb-xl-7 clearfix">
                    <div className="step even">
                      <figure>
                        <img src={getJourneyIcon2Image}  alt="image description" width={40} height={30} />
                      </figure>
                      <p>Check current foot traffic</p>
                    </div>
                  </div>
                  <div className="pb-4 pb-xl-7 step-wrap clearfix">
                    <div className="step odd">
                      <figure>
                        <img src={getJourneyIcon3Image}  alt="image description" width={35} height={30} />
                      </figure>
                      <p> Join queue virtually &amp; from anywhere</p>
                    </div>
                  </div>
                  <div className="pb-4 pb-xl-7 step-wrap clearfix">
                    <div className="step even">
                      <figure>
                        <img src={getJourneyIcon4Image}  alt="image description" width={30} height={30} />
                      </figure>
                      <p>Verify position &amp; estimated wait time</p>
                    </div>
                  </div>
                  <div className="pb-4 pb-xl-7 step-wrap clearfix">
                    <div className="step odd">
                      <figure>
                        <img src={getJourneyIcon5Image} alt="image description" width={45} height={45} />
                      </figure>
                      <p>Shows the fastest route </p>
                    </div>
                  </div>
                  <div className="pb-4 pb-xl-7 step-wrap clearfix">
                    <div className="step even">
                      <figure>
                        <img src={getJourneyIcon6Image} alt="image description" width={35} height={35} />
                      </figure>
                      <p>Automatically check-in &amp; out </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="info-section">
          <div className="container">
            <div className="row pt-7 pt-md-5 pt-lg-7 pt-xl-8 text-center">
              <div className="col-12 col-md-4">
                <div className="txt-wrap">
                  <figure className="icon-holder">
                    <img src={getBottomIcon1} alt="image description" height={40} width={40} />
                  </figure>
                  <strong>Find where you're going.</strong>
                </div>
                <figure className="phone-screen">
                  <img src={getPhone1} from alt="image description" height={400} width={265} />
                </figure>
              </div>
              <div className="col-12 col-md-4">
                <div className="txt-wrap">
                  <figure className="icon-holder">
                    <img src={getBottomIcon2} alt="image description" height={40} width={40} />
                  </figure>
                  <strong>Save your spot in 'line'. </strong>
                </div>
                <figure className="phone-screen">
                  <img src={getPhone2} alt="image description" height={400} width={265} />
                </figure>
              </div>
              <div className="col-12 col-md-4">
                <div className="txt-wrap">
                  <figure className="icon-holder">
                    <img src={getBottomIcon3} alt="image description" height={40} width={40} />
                  </figure>
                  <strong>Get notified when to arrive.</strong>
                </div>
                <figure className="phone-screen">
                  <img src={getPhone3} alt="image description" height={400} width={265} />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer className="footer d-md-flex justify-content-md-between align-items-md-center">
        <div className="container d-md-flex align-items-center">
        <div className="download-links d-md-flex d-xs-flex align-items-md-center" > 
          <a target="blank" title="Click to open link in new tab" href="https://apps.apple.com/us/app/id1526847707">
            <img src={getWhitePlayApp} width="230" height="35" alt="App Store" />
          </a>
          <a target="blank" title="Click to open link in new tab" href="https://play.google.com/store/apps/details?id=com.mobilelive.standin&hl=en">
            <img src={getGooglePlayApp} width="230" height="35" alt="Google Play" />
          </a>
        </div>
        <div className="contact">
          <h4>Questions?</h4>
          <p>
            <strong>For all questions,</strong>&nbsp; please reach out to
          </p>
          <a target="blank" href="mailto:questions@standin.ca?subject=Product Support">questions@standin.ca</a>
        </div>
        </div>
      </footer>
    </div>
  );
};

export default Home;
